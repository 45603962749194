import React from "react";

const Enjoy = () => {
  return (
    <section class="home-bg2 bg-cover w-white">
      <div class="container main center">
        <p class="title p mt-2 ">
          Join us at JILIKO today and{" "}
          <span class="w-main"> have fun playing casino games!</span>
        </p>
        <p class="p">
          If you are looking for a safe and trustworthy gambling site in the
          Philippines, Jiliko is your answer. This unique online casino offers
          the best gambling software but they’re not just limited to virtual
          games! Jiliko has all kinds of entertainment facilities, such as slot
          machines and roulette tables. In fact, it outweighs most other online
          casinos on the market today with better security measures, advanced
          technology and more responsive customer service. Now there is a
          special promotion, whether it is a new member or an old member "Jili
          Free 100 php", players who love jiliko slot should not miss it.
        </p>
        <a
          href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
          target="_blank"
          class="btn w2"
          rel="noreferrer"
        >
          Sign up
        </a>
      </div>
    </section>
  );
};

export default Enjoy;
