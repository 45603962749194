import React from "react"
import Header from "../header";
import  Footer  from "../footer";
import { Outlet } from "react-router";
import SideNav from "../sideNav";

const Layout = () => {
  return (
    <>
      <Header />
      <SideNav/>
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
// export default Layout;